// ----------------------------------------------------------------------

import { _id } from "../_mock/assets";

const ROOTS = {
	AUTH: "/auth",
	DASHBOARD: "/dashboard",
};

// ----------------------------------------------------------------------

const MOCK_ID = _id[1];
// ----------------------------------------------------------------------

export const paths = {
	minimalUI: "https://mui.com/store/items/minimal-dashboard/",
	about: "/about-us",
	contact: "/contact-us",
	faqs: "/faqs",
	privacy: "/privacy-policy",
	terms: "/terms",
	// AUTH
	auth: {
		jwt: {
			login: `${ROOTS.AUTH}/jwt/login`,
			register: `${ROOTS.AUTH}/jwt/register`,
			reset: `${ROOTS.AUTH}/jwt/reset`,
		},
		oauth2: {
			root: `${ROOTS.AUTH}`,
		},
		sign:`${ROOTS.AUTH}/sign`
	},
	// DASHBOARD
	dashboard: {
		root: ROOTS.DASHBOARD,
		chat: {
			root: `${ROOTS.DASHBOARD}/chat`,
			in_chat: id => `${ROOTS.DASHBOARD}/chat/${id}`,
		},
		store:{
			root: `${ROOTS.DASHBOARD}/store`,
		},
		gpts:{
			root:`${ROOTS.DASHBOARD}/gpts`,
			gpt: id => `${ROOTS.DASHBOARD}/gpts/${id}`,
			create: `${ROOTS.DASHBOARD}/gpts/create`,
		},
		channel:{
			root:`${ROOTS.DASHBOARD}/channel`,
		},
		dms:{
			root:`${ROOTS.DASHBOARD}/dms`,
		},
		saved:{
			root:`${ROOTS.DASHBOARD}/saved`,
		},
		agent: {
			root: `${ROOTS.DASHBOARD}/agent`,
			chat: `${ROOTS.DASHBOARD}/agent/chat`,
			in_chat: id => `${ROOTS.DASHBOARD}/agent/chat?id=${id}`,
			new_conversation: type => `${ROOTS.DASHBOARD}/agent/chat?new_conversation=${type}`,
			plugin_flow: id => `${ROOTS.DASHBOARD}/agent/${id}/plugin_flow`,
			comment: id => `${ROOTS.DASHBOARD}/agent/${id}/comment`,
			external: id => `${ROOTS.DASHBOARD}/agent/${id}/external`,
			log: id => `${ROOTS.DASHBOARD}/agent/${id}/log`,
			statistics: id => `${ROOTS.DASHBOARD}/agent/${id}/statistics`,
			document: id => `${ROOTS.DASHBOARD}/agent/${id}/document`,
			repository: id => `${ROOTS.DASHBOARD}/agent/${id}/repository`,
			new: `${ROOTS.DASHBOARD}/agent/new`,
			details: id => `${ROOTS.DASHBOARD}/agent/${id}`,
			edit: id => `${ROOTS.DASHBOARD}/agent/${id}/edit`,
		},
		plugin: {
			root: `${ROOTS.DASHBOARD}/plugin`,
			in_plugin: id => `${ROOTS.DASHBOARD}/plugin/${id}`,
			data_source: `${ROOTS.DASHBOARD}/data_source`,
			model: `${ROOTS.DASHBOARD}/model`,
			template: {
				root: `${ROOTS.DASHBOARD}/template`,
				edit: id => `${ROOTS.DASHBOARD}/template/${id}/edit`,
			},
			prompt: `${ROOTS.DASHBOARD}/prompt`,
			repository: `${ROOTS.DASHBOARD}/repository`,
		},
		repository: {
			details: id => `${ROOTS.DASHBOARD}/repository/details/${id}`,
			upload_file: id => `${ROOTS.DASHBOARD}/repository/upload-file/${id}`,
		},
		cloud: `${ROOTS.DASHBOARD}/cloud`,
		user: {
			root: `${ROOTS.DASHBOARD}/user`,
			new: `${ROOTS.DASHBOARD}/user/new`,
			favorite: `${ROOTS.DASHBOARD}/user/favorite`,
			studio: `${ROOTS.DASHBOARD}/user/studio`,
			edit: id => `${ROOTS.DASHBOARD}/user/${id}/edit`,
		},
		two: `${ROOTS.DASHBOARD}/two`,
		three: `${ROOTS.DASHBOARD}/three`,
		group: {
			root: `${ROOTS.DASHBOARD}/group`,
			five: `${ROOTS.DASHBOARD}/group/five`,
			six: `${ROOTS.DASHBOARD}/group/six`,
		},
		job: {
			root: `${ROOTS.DASHBOARD}/job`,
			new: `${ROOTS.DASHBOARD}/job/new`,
			details: id => `${ROOTS.DASHBOARD}/job/${id}`,
			edit: id => `${ROOTS.DASHBOARD}/job/${id}/edit`,
			demo: {
				details: `${ROOTS.DASHBOARD}/job/${MOCK_ID}`,
				edit: `${ROOTS.DASHBOARD}/job/${MOCK_ID}/edit`,
			},
		},
		running_direction: {
			root: `${ROOTS.DASHBOARD}/running_direction`,
		},
		organization:{
			management:`${ROOTS.DASHBOARD}/organization/management`,
		}
	},
	app: id => `/app/${id}`,
};
